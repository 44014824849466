// Custom.scss
// Option A: Include all of Bootstrap

/* import only the necessary Bootstrap files */

@import "./../../node_modules/bootstrap/scss/functions";
@import "./../../node_modules/bootstrap/scss/variables";
$theme-colors: ( "primary": #47718F, "secondary":#9CBF75, "light": #ECF1DE, "dark": #D9DEE6);
@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

main {
    .jumbotron {
        padding: 5px 0px !important;
        height: 25vh;
        position: relative;
        display: flex;
        justify-content: center;
        .crossCont {
            display: flex;
            max-width: 1000px;
            margin: 0 auto;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            svg {
                width: 30px;
                height: 30px;
            }
        }
        .container {
            img {
                //min-width: 102px !important;
                width: auto !important;
                height: calc(25vh - 10px);
            }
            .jumbotron-heading {
                font-size: 2.0rem;
            }
            .lead {
                font-size: 1.05rem;
            }
        }
    }
    .container {
        .card-deck {
            height: auto;
            position: relative;
            overflow: hidden;
            min-height: 65vh;
            .card {
                height: max-content;
                .card-body {
                    overflow: hidden;
                    height: max-content;
                    &.expanded {
                        max-height: unset;
                        div {
                            height: unset !important;
                        }
                    }
                }
                .showMoreButton {
                    padding: 10px;
                    text-align: end;
                }
            }
        }
    }
    .album {
        padding: 5px 0px !important;
    }
    .text-muted {
        padding: 10px 0px;
        height: calc(10vh - 11px);
    }
}

@import "./../../node_modules/bootstrap/scss/bootstrap";